<template>
  <div id="screenSize" class="screen-size flex-center-column">
    <div class="login-form c-border">
      <div class="flex-center-column">
        <img src="@/assets/img/logo_transparent_background_horizontal@2x.png" alt>
        <!-- <h4 class="mt-30">用户注册</h4> -->
      </div>
      <!-- <b-form> -->
      <div v-show="step1">
        <b-form-group id="input-group-1" class="mt-60" :class="{'input-group-error':$v.phoneNumber.$error}">
          <b-form-input id="input-1" v-model="$v.phoneNumber.$model" class="input-error" type="text" required placeholder="手机号" />
          <div v-if="!$v.phoneNumber.required" class="error">请填写手机号</div>
          <div v-if="!$v.phoneNumber.isPhoneNumber" class="error"> 请填写正确手机号</div>
          <div v-if="isRegistered" class="my-error"> 手机号已被注册</div>
        </b-form-group>
        <b-form-group id="input-group-2" class="mt-30" :class="{'input-group-error':$v.validtionCode.$error}">
          <div class="validation-input">
            <b-form-input id="input-2" v-model="$v.validtionCode.$model" class="input-error" type="text" required placeholder="验证码" />
            <div class="validation-button flex-center" @click="fetchCode(phoneNumber)">点击获取验证码</div>
          </div>
          <div v-if="!$v.validtionCode.required" class="error">请填写验证码</div>
          <div v-if="!$v.validtionCode.minLength" class="error"> 请填写6位数字验证码</div>
          <div v-if="smscodeSend" class="my-error"> 验证码已发送</div>
          <div v-if="smscodeError" class="my-error"> 验证码错误</div>
        </b-form-group>
        <div class="flex-center-column mt-60">
          <button @click="checkCode(phoneNumber, validtionCode)">下一步</button>
          <div class="flex-center mt-30">
            <router-link to="/" style="color:#666">返回主页</router-link>
            <div style="color:#666; padding:0 10px">|</div>
            <router-link to="/login" style="color:#666">登录</router-link>
          </div>
        </div>
      </div>
      <div v-show="step2">
        <b-form-group id="input-group-3" class="mt-30" :class="{'input-group-error':$v.username.$error}">
          <b-form-input id="input-3" v-model="$v.username.$model" class="input-error" placeholder="用户名" @change="checkName" />
          <div v-if="!$v.username.required" class="error">请填写姓名</div>
          <div v-if="!$v.username.minLength" class="error"> 请填写真实姓名</div>
          <div v-if="usedName" class="my-error"> 姓名已被使用 </div>
        </b-form-group>
        <b-form-group id="input-group-4" class="mt-30" :class="{'input-group-error':$v.password.$error}">
          <b-form-input
            id="input-4"
            v-model="$v.password.$model"
            class="input-error"
            type="password"
            required
            placeholder="密码"
          />
          <div v-if="!$v.password.required" class="error">请填写密码</div>
          <div v-if="!$v.password.minLength" class="error"> 请填写至少8位数字字母密码</div>
        </b-form-group>

        <b-form-group id="input-group-5" class="mt-30" :class="{'input-group-error':$v.passwordAgain.$error}">
          <b-form-input
            id="input-5"
            v-model="$v.passwordAgain.$model"
            type="password"
            class="input-error"
            required
            placeholder="再次确认密码"
            @change="checkPassword"
          />
          <div v-if="!$v.passwordAgain.required" class="error">请再次填写密码</div>
          <div v-if="!$v.passwordAgain.minLength" class="error"> 请填写至少8位数字字母密码</div>
          <div v-if="ispassAgain" class="my-error">两次输入密码不一致</div>
        </b-form-group>
        <div class="flex-center-column mt-60">
          <button @click="register(phoneNumber, username, password, passwordAgain)">注册</button>
          <a href="#" class="mt-30" @click="stepOne">上一步</a>
          <p class="mt-30" style="font-size:0.75rem">点击注册即代表您同意我们的<a target="_blank" href="https://static.ainvestcn.com/Tutorial/%E7%88%B1%E7%8E%A9%E7%89%B9%E9%9A%90%E7%A7%81%E6%94%BF%E7%AD%96%281%29.pdf">隐私政策</a>和<a target="_blank" href="https://static.ainvestcn.com/Tutorial/%E4%B8%80%E5%88%80%E6%99%BA%E6%8A%95%E8%87%AA%E5%8A%A8%E4%BA%A4%E6%98%93%E8%BD%AF%E4%BB%B6%E7%94%A8%E6%88%B7%E4%BD%BF%E7%94%A8%E5%8D%8F%E8%AE%AE%20-%20%E7%94%B5%E5%AD%90%E7%AD%BE%E7%BD%B2%E7%89%88%281%29.pdf">软件使用协议</a></p>
        </div>
      </div>
      <div v-show="step3">
        <p v-if="regSuccess" class="mt-30">您已注册成功，请提供券商信息以方便使用一刀智投！</p>
        <div v-if="pullBroker">

          <b-form-group id="input-group-6" class="mt-30" :class="{'input-group-error':$v.borker.$error}">
            <b-form-select id="input-6" v-model="$v.borker.$model" class="input-error" :options="borkers" value-field="id" text-field="name" value="sdsf" placeholder="使用券商(必选)" />
            <div v-if="!$v.borker.required" class="error">请选择您使用的券商</div>
          </b-form-group>
          <b-form-group id="input-group-7" class="mt-30" :class="{'input-group-error':$v.borkerAccount.$error}">
            <b-form-input id="input-7" v-model="$v.borkerAccount.$model" class="input-error" required placeholder="券商账号" />
            <div v-if="!$v.borkerAccount.required" class="error"> 请输入券商账号</div>
          </b-form-group>

          <b-form-group id="input-group-9" class="mt-30" :class="{'input-group-error':$v.tradePassword.$error}">
            <b-form-input
              id="input-9"
              v-model="$v.tradePassword.$model"
              class="input-error"
              type="password"
              required
              placeholder="交易密码"
            />
            <div v-if="!$v.tradePassword.required" class="error"> 请输入交易密码</div>
          </b-form-group>

          <b-form-group id="input-group-8" class="mt-30" :class="{'input-group-error':$v.borkerPassword.$error}">
            <b-form-input
              id="input-8"
              v-model="$v.borkerPassword.$model"
              type="password"
              class="input-error"
              required
              placeholder="通讯密码(选填)"
            />
          </b-form-group>
        </div>

        <div class="flex-center-column mt-60">
          <button v-if="!pullBroker" class="mb-10" @click="toggleBroker">填写券商信息</button>
          <button v-if="pullBroker" class="mb-10" @click="pullBrokerInfo(phoneNumber, username, borker, password, borkerAccount, borkerPassword, tradePassword)">提交</button>
          <div v-if="!pullBroker" style="color:#666;">或</div>
          <button v-if="!pullBroker" class="second mt-10" @click="fetchDemoAccount">使用模拟盘交易</button>
          <button v-if="pullBroker" class="second mt-10" @click="toggleBroker">返回</button>
          <a class="mt-30" href="#" style="color:#999; font-size:13px" @click="jumpOver">跳过</a>
        </div>
        <!-- <p v-if="addBrokerSuccess" class="mt-60 my-success">券商信息提交成功，请<router-link to="login">登录</router-link>使用一刀智投!</p> -->
      </div>
      <!-- </b-form> -->
    </div>
  </div>
</template>

<script>
import '../../assets/style/common.scss'
import { mapState, mapActions } from 'vuex'
import { required, minLength, helpers } from 'vuelidate/lib/validators'
const isPhoneNumber = helpers.regex('isPhoneNumber', /^1(3|4|5|6|7|8|9)\d{9}$/)

export default {
  data() {
    return {
      step1: true,
      step2: false,
      step3: false,
      phoneNumber: '',
      validtionCode: '',
      username: '',
      borker: null,
      password: '',
      passwordAgain: '',
      borkerAccount: '',
      borkerPassword: '',
      tradePassword: '',

      borkers: [
        { id: null, name: '选择券商' }
      ],
      name: '',
      age: 0,

      isRegistered: false,
      smscodeSend: false,
      smscodeError: false,
      ispassAgain: false,
      regSuccess: false,
      addBrokerSuccess: false,
      usedName: false,
      pullBroker: false
    }
  },

  validations: {
    phoneNumber: {
      required,
      isPhoneNumber
    },
    validtionCode: {
      required,
      minLength: minLength(6)
    },
    username: {
      required,
      minLength: minLength(2)
    },
    borker: {
      required
    },
    password: {
      required,
      minLength: minLength(8)
    },
    passwordAgain: {
      required,
      minLength: minLength(8)
    },
    borkerAccount: {
      required,
      minLength: minLength(6)
    },
    borkerPassword: {
      required,
      minLength: minLength(6)
    },
    tradePassword: {
      required,
      minLength: minLength(6)
    }

  },

  computed: {
    ...mapState({
      loginStatus: state => state.login.loginStatus,
      userName: state => state.login.userName,
      brokerInfoData: state => state.login.brokerInfoData
    })
  },

  mounted() {
    this.getContainerHeight()
  },

  methods: {
    login: function() {
      const loginInfo = {
        username: this.username,
        password: this.password
      }
      // const loginInfo = {
      //   username: 'tiantest03',
      //   password: 'tiantest03'
      // }
      this.$store.dispatch('login/loginAction', loginInfo).then(() => {
        if (this.loginStatus) {
          this.$router.push('leaderboard')
          setTimeout(
            window.alert,
            1000,
            '已为你提供券商模拟账户，请选择策略进行交易！'
          )
        }
      })
    },

    jumpOver: function() {
      const loginInfo = {
        username: this.username,
        password: this.password
      }
      // const loginInfo = {
      //   username: 'tiantest03',
      //   password: 'tiantest03'
      // }
      this.$store.dispatch('login/loginAction', loginInfo).then(() => {
        if (this.loginStatus) {
          this.$router.push('user-account')
        }
      })
    },

    ...mapActions({
      fetchBrokerInfo: 'login/fetchBrokerInfo',
      loginAction: 'login/loginAction'
    }),

    fetchDemoAccount: function() {
      const loginInfo = {
        username: this.username,
        password: this.password
      }
      // const loginInfo = {
      //   username: 'tiantest03',
      //   password: 'tiantest03'
      // }
      this.loginAction(loginInfo).then(() => {
        if (this.loginStatus) {
          this.http.get('api/office_web/get_demo_account/').then(res => {
            console.log('molizhanghu', res)
            const userInfo = {
              nativeCode: 'imAinvestcnabc',
              username: this.userName
            }
            this.fetchBrokerInfo(userInfo).then(res => {
              // console.log('regsiter res', res.data)
              this.$router.push('leaderboard')
              setTimeout(
                confirm(`账户已准备就绪,请选择策略进行交易！\n\n券商：${this.brokerInfoData.securitiesName}\n券商账号：${this.brokerInfoData.securitiesUsername}\n交易密码：${this.brokerInfoData.securitiesPassword}\n\n可在券商信息中查看！`)
                , 1000
              )
            })
          }
          )
        }
      })
    },

    getContainerHeight: function() {
      const screenHeight = document.getElementById('screenSize')
      const pageHeight = window.innerHeight
      screenHeight.style.height = pageHeight + 'px'
    },

    stepOne: function() {
      this.step1 = true
      this.step2 = false
      this.step3 = false
    },

    stepTwo: function() {
      this.step1 = false
      this.step2 = true
      this.step3 = false
    },

    stepThree: function() {
      this.step1 = false
      this.step2 = false
      this.step3 = true
      this.http.get('/v3/securities').then(
        res => {
          console.log(res)
          this.borkers = this.borkers.concat(res.data.data)
        }
      )
    },

    fetchCode: function(phoneNumber) {
      this.$v.phoneNumber.$touch()
      const isPhoneNumber = this.$v.phoneNumber.isPhoneNumber
      if (isPhoneNumber && phoneNumber) {
        const data1 = {
          nativeCode: 'imAinvestcnabc',
          phoneNumber: phoneNumber
        }
        const data2 = {
          nativeKey: 'imAinvestcnabc',
          phoneNumber: phoneNumber
        }
        this.http.post('/v3/usedphonenumber', data1).then(res => {
          if (res.data.data !== 'false') {
            this.isRegistered = true
            console.log('1')
          } else {
            console.log('2')
            this.http.post('/v3/smscode', data2).then(
              this.smscodeSend = true
            )
          }
        })
      }
    },

    checkName: function() {
      console.log('in checkname')
      const data = {
        nativeCode: 'imAinvestcnabc',
        phoneNumber: this.phoneNumber,
        username: this.username
      }
      this.http.post('v3/usedusername', data).then(res => {
        if (res.data.data === 'false') {
          this.usedName = true
        } else {
          this.usedName = false
        }
        console.log('used username', res.data)
      })
    },

    checkCode: function(phoneNumber, smsCode) {
      this.$v.phoneNumber.$touch()
      this.$v.validtionCode.$touch()
      const isPhoneNumber = this.$v.phoneNumber.$invalid
      const isSmsCode = this.$v.validtionCode.$invalid
      if (!isPhoneNumber && !isSmsCode) {
        const data = {
          nativeCode: 'imAinvestcnabc',
          phoneNumber: phoneNumber,
          smsCode: smsCode
        }
        this.http.post('/v3/verifysmscode', data).then(res => {
          console.log('code is ', res)
          if (res.data.code === 20000) {
            this.stepTwo()
          } else {
            this.smscodeError = true
          }
        })
      }
    },

    checkPassword: function() {
      console.log(this.password, this.passwordAgain)
      if (this.password === this.passwordAgain) {
        this.ispassAgain = false
      } else {
        this.ispassAgain = true
      }
    },

    register: function(phoneNumber, username, password, passwordAgain) {
      this.$v.phoneNumber.$touch()
      this.$v.username.$touch()
      this.$v.password.$touch()
      this.$v.passwordAgain.$touch()
      const isPhoneNumber = this.$v.phoneNumber.$invalid
      const isUsername = this.$v.username.$invalid
      const isPassword = this.$v.password.$invalid
      const isPasswordAgain = this.$v.passwordAgain.$invalid

      const data = {
        phonenumber: phoneNumber,
        username: username,
        password: password,
        nativeCode: 'imAinvestcnabc',
        ua: 'chrome'
      }
      if (!isPhoneNumber && !isUsername && !isPassword && !isPasswordAgain) {
        this.http.post('/v3/register', data).then(res => {
          console.log('three is ', res)
          if (res.data.code === 20000) {
            this.stepThree()
            this.regSuccess = true
          } else {
            alert('reg错误！')
          }
        })
      }
    },

    toggleBroker: function() {
      this.pullBroker = !this.pullBroker
      console.log('this.pullBroker', this.pullBroker)
    },

    pullBrokerInfo: function(phoneNumber, username, borker, password, borkerAccount, tradePassword, borkerPassword) {
      // e.preventDefault()
      this.$v.borker.$touch()
      this.$v.tradePassword.$touch()
      this.$v.borkerAccount.$touch()
      const isBorker = this.$v.borker.$invalid
      const isTradePassword = this.$v.tradePassword.$invalid
      const isBorkerAccount = this.$v.borkerAccount.$invalid
      const data = {
        phonenumber: phoneNumber,
        username: username,
        securities: borker,
        password: password,
        securitiesCpass: tradePassword,
        securitiesPassword: borkerPassword,
        securitiesUsername: borkerAccount,
        nativeCode: 'imAinvestcnabc',
        ua: 'chrome'
      }
      if (phoneNumber && !isBorker && !isBorkerAccount && !isTradePassword) {
        this.http.post('/v3/applyyidao', data).then(res => {
          console.log('pull is ', res)
          if (res.data.code === 20000) {
            this.addBrokerSuccess = true
            this.login()
          } else {
            alert('提交错误, 请联系相关人员！')
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
// .screen-size {
//   height: 100%;
// }
.dirty {
  border-color: #5A5;
  background: #EFE;
}

.dirty:focus {
  outline-color: #8E8;
}

.input-group-error {

  .input-error{
  border-color: #f57f6c;
  }

  .error{
    display: block !important;
  }
}

.error {
      font-size: 0.75rem;
    line-height: 1;
    display: none;
    margin-left: 14px;
    margin-top: 0.6875rem;
    display: none;
    color: #f57f6c;
}

.my-error {
      font-size: 0.75rem;
    line-height: 1;
    margin-left: 14px;
    margin-top: 0.6875rem;
    color: #f57f6c;
}
.my-success {
      font-size: 0.75rem;
    line-height: 1;
    margin-left: 14px;
    margin-top: 1.6875rem;
    color: #666;
}

.error:focus {
  outline-color: #F99;
}

.login-form {
  width: 450px;
  padding: 40px 60px;
  border-radius: 5px;

  img {
    width: 50%;
    text-align: center;
  }
}

button {
  width: 100%;
  height: 38px;
  border: 1px solid #ff3333ff;
  background-color: #fff;
  color: #ff3333ff;
  border-radius: 4px;
  font-size: 20px;
  font-family: PingFang-SC-Medium;
  font-weight: 500;
}

button:hover {
  border: 0;
  background: rgba(255, 51, 51, 1);
  color: #fff;
}

button.second{
  border: 1px solid #666;
  background-color: #fff;
  color: #666;
  // opacity: 0.5;
}

button.second:hover{
  border: 0;
  background:#666;
  color: #fff;
  // opacity: 0.5;
}

.validation-input{
  position: relative;
}

.validation-button {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  padding: 0 10px;
  color: #666;
  cursor: pointer;
}

.validation-button:hover{
  color: #eb483f;
}

</style>
